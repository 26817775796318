<template>
    <el-container>
        <el-main>
            <el-card shadow="never">
                <el-form ref="form" label-width="150px">
                    <el-row :gutter="10" class="top_l">
                        <el-col :span="24" :xs="24" class="elel">
                            <div class="readbox">
                                <el-tag size="large">查询是否可用：</el-tag>
                                <el-radio-group v-model="status" @change="radioes">
                                    <el-radio label="" border>全部</el-radio>
                                    <el-radio :label="1" border>是</el-radio>
                                    <el-radio :label="0" border>否</el-radio>
                                </el-radio-group>
                            </div>
                            <div>
                                <el-form-item label="橙券产品编号" style="margin: 0;">
                                    <el-input v-model="outerProductId" style="width: 300px;" clearable
                                        placeholder="请输入橙券产品编号"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="橙券产品名称" style="margin: 0;">
                                    <el-input v-model="name" style="width: 300px;" clearable
                                        placeholder="请输入橙券产品名称"></el-input>
                                </el-form-item>
                            </div>
                            <div>
                                <el-form-item label="品类" style="margin: 0;">
                                    <el-select v-model="type" style="width: 300px;" clearable placeholder=" 请选择品类"
                                        @focus="selectData">
                                        <el-option v-for="item in chengquanProductTypeData" :key="item" :label="item"
                                            :value="item" />
                                    </el-select>
                                </el-form-item>
                            </div>

                            <div style="margin-left: 20px;">
                                <el-button type="primary" @click="dialogAdd = true">新增</el-button>
                                <el-button type="primary" @click="jouShare">搜索</el-button>
                            </div>

                        </el-col>
                    </el-row>
                </el-form>
                <!-- table 表格 -->
                <el-table v-loading="serloading" :data="tableData" :header-cell-style="{ background: '#eef1f6' }"
                    style="width: 100%" border stripe>
                    <el-table-column prop="productId" label="productId"></el-table-column>
                    <el-table-column prop="id" label="ID"></el-table-column>
                    <el-table-column prop="outerProductId" label="产品编号"></el-table-column>
                    <el-table-column prop="name" label="产品名称" min-width="150"></el-table-column>
                    <el-table-column prop="type" label="品类" min-width="150"></el-table-column>
                    <el-table-column label="图片地址">
                        <template v-slot="scope">
                            <img style="width: 100px;" :src="scope.row.imgUrl" alt="" srcset="">
                        </template>
                    </el-table-column>
                    <el-table-column prop="worth" label="价值（元）"></el-table-column>
                    <el-table-column label="是否可用">
                        <template v-slot="scope">
                            <el-switch @change="switchClick(scope.row)" v-model="scope.row.status"
                                style="--el-switch-on-color: #13ce66; --el-switch-off-color: #ff4949" :active-value="1"
                                :inactive-value="0" active-text="是" inactive-text="否" />
                        </template>
                    </el-table-column>
                    <el-table-column prop="createTime" label="创建时间" min-width="100"></el-table-column>
                    <el-table-column prop="updateTime" label="更新时间" min-width="100"></el-table-column>
                    <el-table-column label="操作" width="200" align="center">
                        <template v-slot="scope">
                            <el-button size="small" type="primary" plain @click="reissue(scope.row)">编辑</el-button>
                            <el-button size="small" type="primary" plain @click="deteleClk(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                    <template #empty>
                        <el-empty :description="emptyText" :image-size="100"></el-empty>
                    </template>
                </el-table>
                <!-- 分页 -->
                <div class="pagin">
                    <el-pagination :small="true" v-model:currentPage="currentPage" v-model:page-size="pageSize"
                        :page-sizes="[10, 20, 50, 100]" background layout="total, sizes, prev, pager, next, jumper"
                        :total="+totals" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
                </div>
                <!-- 新增 -->
                <el-dialog v-model="dialogAdd" title="新增产品" width="50%" :fullscreen="fullscreenshow"
                    @close="closeDialog">
                    <el-form :model="ruleForm" :rules="rules" ref="addruleForm" label-width="150px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="产品编号" prop="outerProductId">
                                    <el-input v-model="ruleForm.outerProductId" clearable
                                        placeholder="请输入产品编号"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="品类" prop="type">
                                    <el-select v-model="ruleForm.type" clearable placeholder=" 请选择品类"
                                        @focus="selectData">
                                        <el-option v-for="item in chengquanProductTypeData" :key="item" :label="item"
                                            :value="item" />
                                    </el-select>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="产品名称" prop="name">
                                    <el-input v-model="ruleForm.name" clearable placeholder="请输入产品名称"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="价值（元）" prop="worth">
                                    <el-input v-model="ruleForm.worth" clearable placeholder="请输入价值（元）"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="24">
                                <!-- prop="imgUrl" -->
                                <el-form-item label="图片">
                                    <el-upload class="el-upload-box" action="aaa" list-type="picture-card"
                                        :auto-upload="false" drag :on-change="OnChange" :limit="1" :multiple="false"
                                        :on-remove="onremove" :http-request="handleUploadFiles" ref="uploads">
                                        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                    </el-upload>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="submitClick('addruleForm')">提交</el-button>
                            <el-button @click="resetClick('addruleForm')">重置</el-button>
                        </span>
                    </template>
                </el-dialog>
                <!-- 编辑 -->
                <el-dialog v-model="dialogAddEdit" title="编辑产品" width="50%" :fullscreen="fullscreenshow"
                    @close="closeDialogEdit">
                    <el-form :model="ruleFormEdit" :rules="rules" ref="addruleFormEdit" label-width="150px"
                        class="demo-ruleForm">
                        <el-row :gutter="20">
                            <el-col :span="12" :xs="24">
                                <el-form-item label="产品编号" prop="outerProductId">
                                    <el-input v-model="ruleFormEdit.outerProductId" clearable
                                        placeholder="请输入产品编号"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="品类" prop="type">
                                    <el-select v-model="ruleFormEdit.type" clearable placeholder=" 请选择品类"
                                        @focus="selectData">
                                        <el-option v-for="item in chengquanProductTypeData" :key="item" :label="item"
                                            :value="item" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12" :xs="24">
                                <el-form-item label="产品名称" prop="name">
                                    <el-input v-model="ruleFormEdit.name" clearable placeholder="请输入产品名称"></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="12" :xs="24">
                                <el-form-item label="价值（元）" prop="worth">
                                    <el-input v-model="ruleFormEdit.worth" clearable placeholder="请输入价值（元）"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="24" :xs="24">
                                <el-row :gutter="20">
                                    <el-col :span="6" :xs="24">
                                        <el-form-item label="原图片">
                                            <img :src="ruleFormEdit.imgUrl" alt="" srcset="" style="width: 150px;">
                                        </el-form-item>
                                    </el-col>
                                    <el-col :span="18" :xs="24">
                                        <el-form-item label="新图片">
                                            <el-upload class="el-upload-box" action="aaa" list-type="picture-card"
                                                :file-list="imgFileListEdit" :auto-upload="false" drag
                                                :on-change="OnChangeEdit" :on-remove="onremoveEdit" :limit="1"
                                                :multiple="false" :http-request="handleUploadFilesEdit"
                                                ref="uploadsEdit">
                                                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                            </el-upload>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                    </el-form>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button type="primary" @click="submitClickEdit('addruleFormEdit')">提交</el-button>
                            <el-button @click="dialogAddEdit = false">取消</el-button>
                        </span>
                    </template>
                </el-dialog>
            </el-card>
        </el-main>
    </el-container>
</template>

<script>

export default {
    name: 'chengquanProductInfo',
    data() {
        return {
            fullscreenshow: false,
            emptyText: '暂无数据',
            currentPage: 1,
            pageSize: 10,
            tableData: [],
            totals: null,
            serloading: false,
            productId: '',
            uuid: '',
            outerProductId: '',
            name: '',
            status: '',
            type: '',
            // 新增
            dialogAdd: false,
            ruleForm: {
                outerProductId: '',
                type: '',
                name: '',
                imgUrl: '',
                worth: '',
            },
            dialogAddEdit: false,
            ruleFormEdit: {},
            imgFileListEdit: [],
            fd: '',
            fdEdit: '',
            imgFileList: [],
            rules: {
                outerProductId: [{
                    required: true,
                    message: '请输入产品编号',
                    trigger: 'blur'
                }],
                type: [{
                    required: true,
                    message: '请选择品类',
                    trigger: 'change'
                }],
                name: [{
                    required: true,
                    message: '请输入产品名称',
                    trigger: 'blur'
                }],
                imgUrl: [{
                    required: true,
                    message: '请输入图片地址',
                    trigger: 'blur'
                }],
                worth: [{
                    required: true,
                    message: '请输入价值（元）',
                    trigger: 'blur'
                }],
            },

            chengquanProductTypeData: []
        };

    },
    created() {
        if (document.body.clientWidth < 768) {
            this.fullscreenshow = true;
        } else {
            this.fullscreenshow = false;
        }

        this.productId = this.$TOOL.data.get('DATA_SELECTEED');
        // 获取列表
        this.chengquanProductInfo();
    },
    watch: {
        '$store.state.dataSelected'(newval) {
            if (this.productId != newval) {
                this.productId = newval;
                // 获取列表
                this.chengquanProductInfo();
            }
        }
    },
    methods: {
        selectData() {
            this.chengquanProductTypelist()
        },
        // 品类列表
        chengquanProductTypelist() {
            this.$HTTP.post(
                'chengquanProductType/query', {},
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.chengquanProductTypeData = response.data.data;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 获取列表
        chengquanProductInfo() {
            this.serloading = true;
            this.$HTTP.post(
                'chengquanProductInfo/query',
                {
                    productId: this.productId,
                    pageNumber: this.currentPage,
                    pageSize: this.pageSize,
                    uuid: this.uuid,
                    outerProductId: this.outerProductId,
                    type: this.type,
                    name: this.name,
                    status: this.status,
                },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.tableData = response.data.data.records;
                            this.totals = response.data.data.total;
                            this.serloading = false;
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 搜索
        jouShare() {
            this.currentPage = 1;
            // 获取列表
            this.chengquanProductInfo();
        },
        //查询是否可用
        radioes() {
            this.currentPage = 1;
            this.chengquanProductInfo();
        },
        // 是否可用
        switchClick(row) {
            this.$HTTP.post(
                'chengquanProductInfo/updateStatus', {
                id: row.id,
                status: row.status,
                productId: this.productId
            },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.$message.success(response.data.msg);
                            // 获取列表
                            this.chengquanProductInfo();
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        // 删除
        deteleClk(row) {
            this.$HTTP.post(
                'chengquanProductInfo/delete', {
                id: row.id,
                productId: this.productId
            },
                response => {
                    if (response.status >= 200 && response.status < 300) {
                        if (response.data.code == 0) {
                            this.$message.success(response.data.msg);
                            // 获取列表
                            this.chengquanProductInfo();
                        } else {
                            this.$message.error(response.data.msg);
                        }
                    } else {
                        console.log(response.message);
                    }
                }
            );
        },
        handleUploadFiles(param) {
            this.fd.append('imgUrl', param.file)
        },
        OnChange(file) {
            this.imgFileList.push(file)
        },
        onremove() {
            this.imgFileList = []
        },
        closeDialog() {
            this.dialogAdd = false;
            this.$refs.uploads.clearFiles();
        },
        // 新增-配置
        submitClick(formName) {
            if (this.imgFileList.length > 0) {
                this.fd = new FormData();
                this.$refs.uploads.submit();
                this.$refs[formName].validate(valid => {
                    if (valid) {
                        this.fd.append('productId', this.productId)
                        this.fd.append('outerProductId', this.ruleForm.outerProductId)
                        this.fd.append('type', this.ruleForm.type)
                        this.fd.append('name', this.ruleForm.name)
                        this.fd.append('worth', this.ruleForm.worth)
                        this.$HTTP.post(
                            'chengquanProductInfo/add', this.fd, response => {
                                if (response.status >= 200 && response.status < 300) {
                                    if (response.data.code == 0) {
                                        this.dialogAdd = false;
                                        this.$message.success(response.data.msg);
                                        this.resetClick(formName);
                                        this.imgFileList = []
                                        // 获取列表
                                        this.chengquanProductInfo();
                                    } else {
                                        this.$message.error(response.data.msg);
                                    }
                                } else {
                                    console.log(response.message);
                                }
                            }
                        );
                    } else {
                        return false;
                    }
                });
            } else {
                this.$message.error("请选择图片");
            }

        },
        // 编辑
        reissue(item) {
            this.ruleFormEdit = item;
            this.dialogAddEdit = true
        },
        handleUploadFilesEdit(param) {
            this.fdEdit.append('imgUrl', param.file)
        },
        OnChangeEdit(file) {
            this.imgFileListEdit.push(file)

        },
        onremoveEdit() {
            this.imgFileListEdit = []
        },
        closeDialogEdit() {
            this.dialogAdd = false;
            this.$refs.uploadsEdit.clearFiles();
        },
        submitClickEdit(formName) {
            this.fdEdit = new FormData();
            this.$refs.uploadsEdit.submit();
            this.$refs[formName].validate(valid => {
                if (valid) {
                    this.fdEdit.append('productId', this.productId)
                    this.fdEdit.append('outerProductId', this.ruleFormEdit.outerProductId)
                    this.fdEdit.append('type', this.ruleFormEdit.type)
                    this.fdEdit.append('name', this.ruleFormEdit.name)
                    this.fdEdit.append('worth', this.ruleFormEdit.worth)
                    this.fdEdit.append('id', this.ruleFormEdit.id)


                    this.$HTTP.post(
                        'chengquanProductInfo/update', this.fdEdit, response => {
                            if (response.status >= 200 && response.status < 300) {
                                if (response.data.code == 0) {
                                    this.dialogAddEdit = false;
                                    this.imgFileListEdit = []
                                    this.$message.success(response.data.msg);
                                    // 获取列表
                                    this.chengquanProductInfo();
                                } else {
                                    this.$message.error(response.data.msg);
                                }
                            } else {
                                console.log(response.message);
                            }
                        }
                    );
                } else {
                    return false;
                }
            });


        },
        // 重置
        resetClick(name) {
            this.$refs[name].resetFields();
        },
        // 分页
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            // 获取列表
            this.chengquanProductInfo();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            // 获取列表
            this.chengquanProductInfo();
        }
    }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.el-input-number {
    width: 100%;
}

.readbox {
    display: inline-block;

    span {
        margin-right: 10px;
        font-size: 16px;
    }
}

.elel {
    display: flex;
    align-items: center;
}
</style>
